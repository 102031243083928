<template>
  <div>
    <Head :title="$t('pledgeMine.title')" ></Head>
    <div class="center" style="padding: 0 20px;" :style="'width:'+$setWidth">
      
      <div class="title">{{$t('pledgeMine.title')}}</div>
      <div class="pledge" v-if="info.deposit!=undefined">
        <div class="pledge_box" style="padding-bottom: 10px;margin-bottom:20px;" v-for="(item,index) in info.user" :key="index" v-if="item.nType==type">

          <div class="pledge_top flex_row" style="align-items: flex-start;">
            <div style="position: relative;">
              <div style="position: absolute;color:#15B3B1;font-weight: bold;top:35%;left:18%;font-size:0.8rem;">M{{Number(type)+1}}</div>
              <img style="width: 50px;margin-right: 10px;" src="@/image/pledge_bg.png" alt="">
            </div>
            <div>
              <div style="font-size: 0.7rem;margin-top: 5px;">{{$t('pledgeMine.text1')}}</div>
              <div style="font-size: 0.8rem;margin-top: 3px;">{{(token==0?1:5000)*item.count}} {{tokenList[token]}}</div>
              <div style="font-size: 0.8rem;margin-top: 3px;">{{$t('pledgeMine.text2')}}：{{item.mt}}{{tokenList[(token==0?1:0)]}}</div>
            </div>
          </div>
          <div class="pledge_jdt" style="width:100%;"><p :style="{width:item.time*1000>time?timeLine(item.time):'100%'}" /></div>
          <div class="flex_lr" style="font-size: 0.7rem">
            <div >{{getDate((item.time-Number(info.deposit[type][0])*24*60*60)*1000)}}</div>
            <div>{{getDate(item.time*1000)}}</div>
          </div>
          <div v-if="item.status==0">
            <div v-if="item.time*1000<time" class="pledge_btn flex_center hand" @click="redeem(index)">{{$t('pledgeMine.text3')}}</div>
            <div v-else class="pledge_btn flex_center hand" style="background: #989898;">{{$t('pledgeMine.text3')}}</div>
          </div>
          <div v-else class="pledge_btn flex_center hand" style="background: #989898;">{{$t('pledgeMine.text4')}}</div>
        </div>
      </div>
      <!-- 空 -->
      <div class="y_center" v-if="showNull()">
        <img src="@/image/null.png" style="width:70%;margin-top: 100px;" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';

export default {
  components: {},
  data() {
    return {
      type:-1,
      token:0,
      loading:0,
      info:[],
      tokenList:['MT','ULME'],
      ulm_num:{},
      time:new Date().getTime()
    }
  },
  async mounted () {
    await this.$onLaunched;
    this.type = this.$route.query.type
    this.token = this.$route.query.token
    this.init();
  },
  methods: {
    init() {
      const that=this
      let token='ulmr'
      if(this.token==0){
        token='mtr'
      }
      this.dd()
      walletHelper.getContract(token).methods.getDepositType().call().then(res=>{
          console.log(res)
          that.$set(that.info,'deposit',that.splitArr(res,4))
          this.closeLoading()
      })
      walletHelper.getContract(token).methods.depositList(walletHelper.getAddress()).call().then(res=>{
          console.log('user',res)
          that.$set(that.info,'user',that.splitUser(res))
      })


    },
    getDate(timestamp){
      var date = new Date(timestamp)
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = date.getDate() + ' ';
      var h = date.getHours() + ':';
      var m = date.getMinutes() + ':';
      var s = date.getSeconds();
      return Y+M+D+h+m+s;
    },
    timeLine(time){
      var start = (time-Number(this.info.deposit[this.type][0])*24*60*60)*1000
      return 100/(time*1000-start)*(this.time-start)+'%'
    },
    splitUser(list){
      var arrOuter = []
      for(var a in list){
        if(a%5==0){
          var data={}
              data.nType = list[a]
              data.count = list[Number(a)+1]
              data.time = list[Number(a)+2]
              data.status = list[Number(a)+3]
              data.mt = walletHelper.Wei(list[Number(a)+4])
          arrOuter.push(data)
        }
      }
      return arrOuter
    },
    showNull(){
      for(var a in this.info.user){
        if(this.info.user[a].nType==this.type){
          return false
        }
      }
      return true
    },
    splitArr(data, senArrLen) {
      //处理成len个一组的数据
      let data_len = data.length;
      let arrOuter_len = data_len % senArrLen === 0 ? data_len / senArrLen : parseInt((data_len /
          senArrLen) + '') + 1;
      let arrSec_len = data_len > senArrLen ? senArrLen : data_len; //内层数组的长度
      let arrOuter = new Array(arrOuter_len); //最外层数组
      let arrOuter_index = 0; //外层数组的子元素下标
      // console.log(data_len % len);
      for (let i = 0; i < data_len; i++) {
          if (i % senArrLen === 0) {
              this.ulm_num[i/senArrLen] = ''
              arrOuter_index++;
              let len = arrSec_len * arrOuter_index;
              //将内层数组的长度最小取决于数据长度对len取余，平时最内层由下面赋值决定
              arrOuter[arrOuter_index - 1] = new Array(data_len % senArrLen);
              if (arrOuter_index === arrOuter_len) //最后一组
                  data_len % senArrLen === 0 ?
                  len = data_len % senArrLen + senArrLen * arrOuter_index :
                  len = data_len % senArrLen + senArrLen * (arrOuter_index - 1);
              let arrSec_index = 0; //第二层数组的索引
              for (let k = i; k < len; k++) { //第一层数组的开始取决于第二层数组长度*当前第一层的索引
                  arrOuter[arrOuter_index - 1][arrSec_index] = data[k];
                  if(arrSec_index==1){
                    arrOuter[arrOuter_index - 1][arrSec_index] = walletHelper.Wei(data[k])
                  }
                  arrSec_index++;
              }
          }
      }
      return arrOuter
    },
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '正在查询',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    redeem(index){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      let token='ulmr'
      if(this.token==0){
        token='mtr'
      }
      
      this.sendWeb3(walletHelper.getContract(token).methods.withdraw(index)).then(res=>{
          console.log(res)
          that.closeLoading('赎回成功')
          that.init()
      })
    },

  },
};
</script>
<style lang="scss" scoped>
.title{
  font-size: 1.1rem;
  font-family: Microsoft YaHei;
  color: #00FFEA;
  margin: 10px 0;
}
.pledge{
  .pledge_box{
    width: 100%;
    background: #0C2379;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_top{
    border-bottom: 1px solid #0B2FB8;
    padding: 15px 0;
  }
  .pledge_jdt{
    width: 0;
    flex: 1;
    height: 6px;
    margin: 10px 0;
    background: #0F5057;
    border-radius: 50px;
    overflow: hidden;
    p{
      height: 100%;
      border-radius: 50px;
      background: #07C6D9;
    }
  }
  .pledge_btn{
    width: 100%;
    padding: 8px 0;
    // color: #000;
    background: #07C6D9;
    border-radius: 5px;
    font-size: 0.7rem;
    margin: 10px 0;
  }
}
</style>